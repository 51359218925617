import { OtpType } from "@gada-saas/web-core";
import { Button, Col, RadioGroup, Text } from "@gada-saas/web-ui";
import { Divider } from "@material-ui/core";
import clsx from "clsx";
import * as React from "react";
import OtpInput from "react-otp-input";
import useStyles from "./styles";
import { OtpFlowModalContentProps } from "./types";
import useOtpFlowModalContent from "./useOtpFlowModalContent";

const OtpFlowModalContent: React.VFC<OtpFlowModalContentProps> = ({
  verificationType,
  phoneNumber,
  toggleUnregisteredModal,
  toggleBlacklistedNumberModal,
  handleNext,
}) => {
  const classes = useStyles();

  const {
    otpFormik,
    getOtpResponse,
    useTimer,
    remainingTime,
    haveRequestedOtpBefore,
    tokenFormik,
    handleOtpMethodChange,
    handleOtpInputChange,
    startOtpResponse,
  } = useOtpFlowModalContent({
    verificationType,
    phoneNumber,
    toggleBlacklistedNumberModal,
    toggleUnregisteredModal,
    handleNext,
  });

  return (
    <Col>
      <Col className={classes.container}>
        <Text>
          Kode OTP akan dikirimkan ke nomor{" "}
          <span className={classes.bold}>
            +62
            {phoneNumber.startsWith("0")
              ? phoneNumber.substring(1)
              : phoneNumber}
          </span>{" "}
          Pilih metode pengiriman:
        </Text>

        <RadioGroup
          id="radio_group_otp_method"
          name="otpType"
          row
          className={classes.otpForm}
          value={otpFormik.values.otpType}
          options={[
            {
              id: "radio_button_whatsapp",
              label: "WhatsApp",
              value: OtpType.WHATSAPP,
            },
            { id: "radio_button_sms", label: "SMS", value: OtpType.SMS },
          ]}
          handleChange={handleOtpMethodChange}
          radioProps={{ disabled: getOtpResponse.isLoading || useTimer }}
        />

        <Button
          id="button_send_otp"
          variant="primary"
          isLoading={getOtpResponse.isLoading}
          disabled={getOtpResponse.isLoading || useTimer}
          onClick={() => otpFormik.handleSubmit()}
        >
          {useTimer
            ? `Kirim Kode OTP ${remainingTime.format("mm:ss")}`
            : "Kirim Kode OTP"}
        </Button>
      </Col>

      {haveRequestedOtpBefore && (
        <>
          <Divider className={classes.divider} />

          <Col className={classes.container}>
            <Text className={classes.optCodeLabel}>
              Kami sudah mengirimkan kode OTP. Masukkan 4 digit kode:
            </Text>

            {/* Todo - Fix OtpInput */}
            <OtpInput
              data-testid="input_otp"
              value={tokenFormik.values.otpCode}
              numInputs={4}
              onChange={handleOtpInputChange}
              containerStyle={clsx(classes.otpCodeContainer, {
                [classes.otpCodeContainerError]: tokenFormik.errors.otpCode,
              })}
              inputStyle={clsx(classes.otpCodeInput, {
                [classes.otpCodeInputError]: tokenFormik.errors.otpCode,
              })}
              focusStyle={classes.otpCodeInputFocus}
            />

            {tokenFormik.errors.otpCode && (
              <Text
                variant="body2"
                ink="redPrimary"
                className={classes.otpCodeErrorText}
              >
                {tokenFormik.errors.otpCode}
              </Text>
            )}

            <Button
              id="button_otp_submit"
              variant="primary"
              onClick={() => tokenFormik.handleSubmit()}
              isLoading={startOtpResponse.isLoading}
              disabled={startOtpResponse.isLoading}
            >
              Konfirmasi
            </Button>
          </Col>
        </>
      )}
    </Col>
  );
};

export default OtpFlowModalContent;
