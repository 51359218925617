import OtpFlowModalContent from "../OtpFlowModalContent";
import { Modal } from "@gada-saas/web-ui";
import * as React from "react";
import useStyles from "./styles";
import { OtpFlowModalProps } from "./types";

const OtpFlowModal: React.VFC<OtpFlowModalProps> = ({
  verificationType,
  phoneNumber,
  openOtpFlowModal,
  withCloseButton = true,
  setOtpFlowModal,
  toggleOtpFlowModal,
  openUnregisteredModal,
  openBlackListedModal,
  handleNext,
}) => {
  const classes = useStyles();

  const toggleUnregisteredModal = React.useCallback(() => {
    setOtpFlowModal(false);
    openUnregisteredModal?.();
  }, [openUnregisteredModal, setOtpFlowModal]);

  const toggleBlaclistedNumberModal = React.useCallback(() => {
    setOtpFlowModal(false);
    openBlackListedModal?.();
  }, [openBlackListedModal, setOtpFlowModal]);

  return (
    <Modal
      withCloseButton={withCloseButton}
      open={openOtpFlowModal}
      title="Kode OTP"
      content={
        <OtpFlowModalContent
          phoneNumber={phoneNumber}
          verificationType={verificationType}
          toggleUnregisteredModal={toggleUnregisteredModal}
          toggleBlacklistedNumberModal={toggleBlaclistedNumberModal}
          handleNext={handleNext}
        />
      }
      modalContentProps={{ className: classes.noPadding }}
      modalActionProps={{ className: classes.noPadding }}
      closeButtonProps={{ id: "button_otp_close" }}
      onClickCloseButton={toggleOtpFlowModal}
    />
  );
};

export default OtpFlowModal;
