import {
  Box,
  FormControl,
  FormHelperText,
  OutlinedInput,
} from "@material-ui/core";
import * as React from "react";
import Label from "../Label";
import useStyles from "./styles";
import { PhoneFieldProps } from "./types";

const countryCode = "+62";

const PhoneField: React.VFC<PhoneFieldProps> = ({
  id,
  name,
  label,
  labelInk,
  disabled = false,
  errorText,
  placeholder = "857xxxxxxxx",
  value,
  onChange,
  onBlur,
}: PhoneFieldProps) => {
  const classes = useStyles();
  const isError = Boolean(errorText);

  return (
    <Box className={classes.fieldWrapper}>
      {label ? <Label id={id} label={label} ink={labelInk} /> : null}
      <Box className={classes.inputWrapper}>
        <OutlinedInput
          id={`${id}-country`}
          disabled
          value={countryCode}
          className={classes.countryCode}
          error={isError}
        />
        <FormControl
          disabled={disabled}
          className={classes.numInput}
          error={isError}
        >
          <OutlinedInput
            id={id}
            placeholder={placeholder}
            inputProps={{
              name,
              value,
              onChange,
              onBlur,
            }}
          />
        </FormControl>
      </Box>
      {errorText && (
        <FormHelperText error id={id}>
          {errorText}
        </FormHelperText>
      )}
    </Box>
  );
};

export default PhoneField;
