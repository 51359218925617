import ErrorMessages from "@common/constants/messages";
import { OtpType } from "@gada-saas/web-core";
import * as Yup from "yup";
import { OtpFormValues, TokenFormValues } from "./types";

export const otpFormInitialValues: OtpFormValues = {
  otpType: OtpType.WHATSAPP,
};

export const tokenFormInitialValues: TokenFormValues = {
  otpCode: "",
};

export const otpValidationSchema = Yup.object().shape({
  otpType: Yup.string().required(ErrorMessages.required("Metode OTP")),
});

export const tokenValidationSchema = Yup.object().shape({
  otpCode: Yup.string()
    .required(ErrorMessages.required("Kode OTP"))
    .length(4, ErrorMessages.minimumValue(4, "Kode OTP")),
});
