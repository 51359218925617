import { ErrorMessages } from "@common/constants";
import { useAsyncAction, useInterval } from "@common/hooks";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import {
  OtpType,
  SendOtpResponse,
  setIsLoggedIn,
  setUserToken,
  useGetOtpMutation,
  useLogoutMutation,
  useStartOtpMutation,
  VerificationType,
} from "@gada-saas/web-core";
import { iBaseQueryErrorResponse } from "@gada-saas/web-core/common/api";
import { globalResetAction } from "@gada-saas/web-core/common/redux/actions";
import { Duration } from "dayjs/plugin/duration";
import { useFormik } from "formik";
import { useSegmentAnalytics } from "@miscellaneous/tracking/hooks/useSegmentAnalytics";
import { useSnackbar } from "notistack";
import * as React from "react";
import { useDispatch } from "react-redux";
import {
  otpFormInitialValues,
  otpValidationSchema,
  tokenFormInitialValues,
  tokenValidationSchema,
} from "./forms";
import {
  iUseOtpFlowModalContentProps,
  OtpFormValues,
  TokenFormValues,
} from "./types";

dayjs.extend(duration);

const useOtpFlowModalContent = ({
  verificationType,
  phoneNumber,
  toggleBlacklistedNumberModal,
  toggleUnregisteredModal,
  handleNext,
}: iUseOtpFlowModalContentProps) => {
  const analytics = useSegmentAnalytics();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const [logoutFromServer] = useLogoutMutation();
  const [getOtp, getOtpResponse] = useGetOtpMutation();
  const [startOtp, startOtpResponse] = useAsyncAction<SendOtpResponse>(
    useStartOtpMutation()[0]
  );

  const countDownTimer = 10; // Demo timer: 10 seconds
  const [remainingTime, setRemainingTime] = React.useState<Duration>(
    dayjs.duration(countDownTimer, "second")
  );
  const [useTimer, setUseTimer] = React.useState<boolean>(false);
  const [haveRequestedOtpBefore, setHaveRequestedOtpBefore] =
    React.useState<boolean>(false);

  const tick = React.useCallback(() => {
    if (useTimer) {
      if (remainingTime.format("mm:ss") === "00:00") {
        setUseTimer(false);
      } else {
        setRemainingTime((remainingTime) =>
          remainingTime.subtract(1, "second")
        );
      }
    }
  }, [remainingTime, useTimer]);

  useInterval({ callback: tick, delay: 1000 });

  React.useEffect(() => {
    if (haveRequestedOtpBefore) {
      analytics.track("Verify OTP");
    }
  }, [analytics, haveRequestedOtpBefore]);

  const requestOtp = React.useCallback(
    async (values: OtpFormValues) => {
      try {
        // Ensure the user start with empty cookie
        await logoutFromServer();
        dispatch(globalResetAction());

        const response = await getOtp({
          phoneNumber: phoneNumber,
          otpType: values.otpType,
          clientId: process.env.NEXT_PUBLIC_CLIENT_ID as string,
          verificationType: verificationType,
        }).unwrap();

        if (response.data?.timeout) {
          analytics.track("OTP Requested", {
            phone_number: phoneNumber,
            otp_channel:
              values.otpType === OtpType.WHATSAPP ? "Whatsapp" : "sms",
            entry_point: verificationType.toLowerCase(),
            is_success: true,
          });

          const timeout = response.data.timeout;
          setRemainingTime(dayjs.duration(timeout, "second"));
          if (!useTimer) setUseTimer(true);
          if (!haveRequestedOtpBefore) setHaveRequestedOtpBefore(true);
        }
      } catch (error) {
        analytics.track("OTP Requested", {
          phone_number: phoneNumber,
          otp_channel: values.otpType === OtpType.WHATSAPP ? "Whatsapp" : "sms",
          entry_point: verificationType.toLowerCase(),
          is_success: false,
        });

        const err = error as iBaseQueryErrorResponse;
        if (err.data.problem === "NETWORK_ERROR") {
          enqueueSnackbar(ErrorMessages.noInternet, {
            variant: "error",
          });
        } else if (err.data.data?.errorCode === "USER_IS_NOT_WHITELISTED") {
          toggleBlacklistedNumberModal();
        } else if (err.data.status === 404 || err.data.status === 409) {
          toggleUnregisteredModal();
        } else {
          enqueueSnackbar(err.data.data?.message, { variant: "error" });
        }
      }
    },
    [
      analytics,
      dispatch,
      enqueueSnackbar,
      getOtp,
      haveRequestedOtpBefore,
      logoutFromServer,
      phoneNumber,
      toggleBlacklistedNumberModal,
      toggleUnregisteredModal,
      useTimer,
      verificationType,
    ]
  );

  const otpFormik = useFormik({
    initialValues: otpFormInitialValues,
    validationSchema: otpValidationSchema,
    onSubmit: requestOtp,
  });

  const requestToken = React.useCallback(
    async (values: TokenFormValues) => {
      const response = await startOtp({
        audience: "https://gudangada.com/saas-api",
        clientId: process.env.NEXT_PUBLIC_CLIENT_ID as string,
        otpCode: values.otpCode,
        otpType: otpFormik.values.otpType,
        phoneNumber: phoneNumber,
        verificationType: verificationType,
      });

      if (response.success) {
        analytics.track("OTP Submitted", {
          phone_number: phoneNumber,
          otp_channel:
            otpFormik.values.otpType === OtpType.WHATSAPP ? "Whatsapp" : "sms",
          entry_point: verificationType.toLowerCase(),
          verification_status: "verified",
        });

        setUserToken(response.success.data?.accessToken ?? "");
        if (verificationType === VerificationType.LOGIN) {
          setIsLoggedIn();
        }
        handleNext();
      } else if (response.error) {
        analytics.track("OTP Submitted", {
          phone_number: phoneNumber,
          otp_channel:
            otpFormik.values.otpType === OtpType.WHATSAPP ? "Whatsapp" : "sms",
          entry_point: verificationType.toLowerCase(),
          verification_status: "denied",
        });

        if (response.error.status === 404 || response.error.status === 409) {
          toggleUnregisteredModal();
        }
      }
    },
    [
      analytics,
      handleNext,
      phoneNumber,
      startOtp,
      toggleUnregisteredModal,
      verificationType,
      otpFormik.values.otpType,
    ]
  );

  const tokenFormik = useFormik({
    initialValues: tokenFormInitialValues,
    validationSchema: tokenValidationSchema,
    onSubmit: requestToken,
  });

  const handleOtpMethodChange = React.useCallback(
    (_event: React.ChangeEvent<HTMLInputElement>, value: string) => {
      otpFormik.setFieldValue("otpType", value as OtpType);
    },
    [otpFormik]
  );

  const handleOtpInputChange = React.useCallback(
    (newValue: number) => {
      tokenFormik.setFieldValue("otpCode", newValue.toString());
    },
    [tokenFormik]
  );

  return {
    otpFormik,
    getOtpResponse,
    useTimer,
    remainingTime,
    haveRequestedOtpBefore,
    tokenFormik,
    handleOtpMethodChange,
    handleOtpInputChange,
    startOtpResponse,
  };
};

export default useOtpFlowModalContent;
