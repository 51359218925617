import { makeStyles } from "@material-ui/core";
import { pxToRem } from "../../../utils";

const useClasses = makeStyles({
  fieldWrapper: {
    display: "flex",
    flexDirection: "column",
    marginTop: pxToRem(8),
  },
  inputWrapper: {
    display: "flex",
  },
  countryCode: {
    marginRight: pxToRem(8),
    width: pxToRem(62),
  },
  numInput: {
    flex: 1,
  },
});

export default useClasses;
