import { AppTheme, pxToRem } from "@gada-saas/web-ui";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: AppTheme) => ({
  container: {
    padding: `${pxToRem(16)} ${pxToRem(24)}`,
  },
  bold: {
    fontWeight: "bold",
  },
  otpForm: {
    display: "flex",
    marginTop: pxToRem(16),
    marginBottom: pxToRem(27),
    "& > *": {
      flex: 1,
    },
  },
  divider: {
    height: pxToRem(8),
  },
  optCodeLabel: {
    marginBottom: pxToRem(16),
  },
  otpCodeContainer: {
    justifyContent: "center",
    marginBottom: pxToRem(24),
  },
  otpCodeContainerError: {
    marginBottom: 0,
  },
  otpCodeInput: {
    width: `${pxToRem(51)} !important`,
    height: `${pxToRem(51)} !important`,
    marginRight: pxToRem(16),
    borderRadius: theme.shape.borderRadius,
    borderColor: theme.palette.interface.neutral.secondary,
  },
  otpCodeInputError: {
    borderColor: theme.palette.interface.red.primary,
  },
  otpCodeInputFocus: {
    borderColor: theme.palette.interface.teal.primary,
  },
  otpCodeErrorText: {
    marginBottom: pxToRem(24),
    marginTop: pxToRem(16),
    textAlign: "center",
  },
}));

export default useStyles;
