export interface GetOtpResponse {
  message?: string;
  data: null | { timeout: number };
}

export interface SendOtpResponse {
  message?: string;
  data: null | {
    accessToken: string; // (JWT) used to access API
    idToken: string | null; // (JWT)
    refreshToken: string | null;
  };
}

export interface GetOtpRequest {
  phoneNumber: string;
  otpType: OtpType;
  clientId: string;
  verificationType: VerificationType;
}

export interface SendOtpRequest {
  otpCode: string;
  phoneNumber: string;
  verificationType: VerificationType;
  clientId: string;
  otpType: OtpType;
  audience: "https://gudangada.com/saas-api";
}

export enum OtpType {
  WHATSAPP = "WHATSAPP",
  SMS = "SMS",
}

export enum VerificationType {
  LOGIN = "LOGIN",
  REGISTRATION = "REGISTRATION",
  UPDATE_PHONE = "UPDATE_PHONE",
}
